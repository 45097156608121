import { useLayoutEffect } from "react";

import useCanvas from "@/js/hooks/useCanvas";

import { Connection } from "../types";

// in px
const CIRCLE_BUFFER = 5;
const CIRCLE_RADIUS = 4;

/**
 * Canvas that adds arc'd lines connecting two points, with circles as nodes at the start and end.
 *
 * The useCanvas function handles setup, as well as dpr and resize changes.
 */
export default function AssignmentCanvas({ connections }: { connections: Connection[] }) {
  const { canvasRef, dpr } = useCanvas();

  const draw = () => {
    const context = canvasRef.current.getContext("2d");

    // NOTE: context.canvas.width will still not be adjusted for dpr
    const canvasWidth = canvasRef.current.offsetWidth;

    context.reset();
    context.scale(dpr, dpr);

    // node circles at start/end of each path
    const drawEndpoints = (...arcArgs: Parameters<CanvasPath["arc"]>) => {
      context.beginPath();
      context.arc(...arcArgs);
      context.fillStyle = "#00beb1";
      context.fill();
      context.lineWidth = 2;
      context.strokeStyle = "#fff";
      context.stroke();
    };

    connections
      // render highlighted items last so that they are shown on top of everything else
      .toSorted(({ highlighted }) => (highlighted ? 1 : -1))
      .forEach(({ coords: [from, to], highlighted }) => {
        if (from && to) {
          context.beginPath();
          context.strokeStyle = highlighted ? "rgb(0 132 125 / 100%)" : "rgb(191 197 207 / 50%)";
          context.lineWidth = 2;
          context.moveTo(CIRCLE_BUFFER, from);
          context.arcTo(
            Math.round(canvasWidth / 2),
            from,
            Math.round(canvasWidth / 2),
            to,
            Math.min(24, Math.abs(to - from) / 2),
          );
          context.arcTo(
            Math.round(canvasWidth / 2),
            to,
            canvasWidth,
            to,
            Math.min(24, Math.abs(to - from) / 2),
          );
          context.lineTo(canvasWidth - CIRCLE_BUFFER, to);
          context.stroke();
        }

        if (from) {
          drawEndpoints(CIRCLE_BUFFER, from, CIRCLE_RADIUS, 0, 2 * Math.PI);
        }

        if (to) {
          drawEndpoints(canvasWidth - CIRCLE_BUFFER, to, CIRCLE_RADIUS, 0, 2 * Math.PI);
        }
      });
  };

  useLayoutEffect(() => {
    queueMicrotask(draw);
  });

  return <canvas ref={canvasRef} />;
}

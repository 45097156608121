import type { ColumnTypes, FilterCategories, TabItem } from "./types";

export const ColumnConfig: {
  [K in ColumnTypes]: {
    title: string;
    tabs: TabItem<K>[];
    defaultTab: TabItem<K>["key"];
    categories: { key: FilterCategories<K>; display: string }[];
    placeholder: string;
  };
} = {
  meter: {
    defaultTab: "unassigned",
    title: "Meters",
    tabs: [
      { key: "unassigned", text: "Unassigned" },
      { key: "assigned", text: "Assigned" },
      { key: "all", text: "All" },
    ],
    categories: [
      { key: "type", display: "Meter Type" },
      { key: "utilityProvider", display: "Utility Provider" },
      { key: "accountCredentials", display: "Account Credentials" },
      { key: "source", display: "Source Data" },
    ],
    placeholder: "Enter meter number or address",
  },
  unit: {
    defaultTab: "all",
    title: "Units",
    tabs: [
      { key: "all", text: "All" },
      { key: "LEASED", text: "Leased" },
      { key: "COMMON_AREA", text: "Common Area" },
      { key: "VACANT", text: "Vacant" },
    ],
    categories: [{ key: "assignment", display: "Unit Assignment" }],
    placeholder: "Enter unit name or address",
  },
};

import { ChangeEvent, forwardRef } from "react";

import { classnames } from "@/js/utils/cambio";

import SvgIcon from "../SvgIcon";
import TextOverflow from "../TextOverflow";

interface CheckboxProps {
  /** Whether the checkbox is currently checked */
  checked?: boolean;
  /** Whether to the checkbox as disabled */
  disabled?: boolean;
  /** All checkboxes that belong to the same group should have the same name */
  name?: string;
  /**
   * This should be true for an input that fails validation. It will have a red border. I think the
   * only case for this would be if we don't provide a default and it is a required field.
   */
  invalid?: boolean;
  /**
   * Callback to selecting a checkbox. `checked` is whether the item is now checked as part of this
   * action.
   */
  onChange: (checked: boolean, evt: ChangeEvent<HTMLInputElement>) => void;
  /** Checkbox input text label */
  label: string;
  /** Checkbox input value */
  value?: string;
}

/**
 * Default export, this is a component for a single Checkbox. If using a group of checkboxes, use
 * the CheckboxGroup defined below.
 */
const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ disabled, checked, label, onChange, name, value }, ref) => (
    <label className="Checkbox">
      <input
        ref={ref}
        checked={checked}
        disabled={disabled}
        id={label}
        name={name}
        onChange={disabled ? null : (evt) => onChange(!checked, evt)}
        type="checkbox"
        value={value}
      />
      {checked ?
        <SvgIcon name="check" />
      : null}
      <TextOverflow>{label}</TextOverflow>
    </label>
  ),
);

export default Checkbox;

interface CheckboxGroupProps extends Omit<CheckboxProps, "onChange" | "value" | "label"> {
  /** Name for the overall checkbox fieldset, not an individual checkbox */
  label?: string;
  /**
   * Callback to selecting a checkbox. selectedGroup is the set of selected values with the latest
   * change. Input is the latest item toggled.
   */
  onChange: (
    checkedValues: string[],
    changedValue: string,
    evt: ChangeEvent<HTMLInputElement>,
  ) => void;
  /** List of possible items to select */
  options: {
    value: string;
    label: string;
  }[];
  /** Current 'checked' checkbox values */
  value?: string[];
}

/**
 * Use this component when you are using a group of checkboxes, say, in a Form, and you want to
 * deal with them as a group.
 */
export const CheckboxGroup = forwardRef<HTMLInputElement, CheckboxGroupProps>(
  ({ disabled, name, onChange, options, invalid, label, value }, ref) => (
    <fieldset className={classnames("CheckboxGroup", { invalid })} disabled={disabled} name={label}>
      {options.map((option) => (
        <Checkbox
          key={option.value}
          {...{
            disabled,
            name,
            checked: value.includes(option.value),
            ...option,
          }}
          onChange={(isChecked, evt) =>
            onChange(
              !isChecked ? value.filter((val) => val !== option.value) : value.concat(option.value),
              option.value,
              evt,
            )
          }
        />
      ))}
    </fieldset>
  ),
);

import type { ColumnTypes, Filter, FilterCategories, SMAModalItem } from "../types";

import { useState } from "react";

import Button from "@/components/Button";
import IconButton from "@/components/Button/IconButton";
import Checkbox from "@/components/Checkbox";

import { ColumnConfig } from "../constants";

/**
 * The Category Filter for the SpaceMeterAssignmentModal, where each value seen in the list of items
 * is shown as a checkbox within certain categories (depending on the column type, meter or unit).
 * The user can then toggle each checkbox to filter in/out the items in the list.
 */
export default function CategoryFiltersPicker<C extends ColumnTypes>({
  type,
  allItems,
  setFilter,
  selectedCategories,
}: {
  /** Meter or unit */
  type: C;
  /**
   * All items, unfiltered, that can be shown in the AssignmentColumn. It's from these that we get
   * the list of items we can filter.
   */
  allItems: SMAModalItem<C>[];
  /**
   * This is the callback for any filter selection changes, since we have one main state for the
   * entire modal. This callback is configured to only accept categories.
   */
  setFilter: (value: any) => void;
  /**
   * Current list of filter categories with their selected values, ie, which items in each category
   * should be displayed as checked.
   */
  selectedCategories: Filter<C>["categories"];
}) {
  const { categories } = ColumnConfig[type];

  const setCategoryValue = (categoryKey: string) => (value: string, only?: boolean) =>
    setFilter(
      selectedCategories.map((category) =>
        category.key !== categoryKey ?
          category
        : {
            ...category,
            values:
              only ? [value]
              : category.values.includes(value) ? category.values.filter((val) => val !== value)
              : category.values.concat(value),
          },
      ),
    );

  return (
    <div className="CategoryFiltersPicker" onClick={(evt) => evt.stopPropagation()}>
      <ul>
        {categories.map((category) => (
          <CollapsibleFilterGroup
            key={category.key}
            allItems={allItems}
            field={category.key}
            selectedCategoryValues={
              selectedCategories.find(({ key }) => key === category.key)?.values
            }
            setCategoryValue={setCategoryValue(category.key)}
            title={category.display}
          />
        ))}
      </ul>
      <footer>
        <Button
          flavor="link"
          onClick={() => setFilter(selectedCategories.map(({ key }) => ({ key, values: [] })))}
        >
          Clear filters
        </Button>
      </footer>
    </div>
  );
}

function CollapsibleFilterGroup<C extends ColumnTypes>({
  field,
  title,
  allItems,
  selectedCategoryValues,
  setCategoryValue,
}: {
  field: FilterCategories<C>;
  title: string;
  allItems: SMAModalItem<C>[];
  selectedCategoryValues: string[];
  setCategoryValue: (value: string, only?: boolean) => void;
}) {
  const [hidden, setHidden] = useState(false);

  const valueSet = [...new Set(allItems.flatMap((item) => item.tags[field]))]
    .filter(Boolean)
    .toSorted();

  if (!valueSet.length) {
    return null;
  }

  return (
    <li className="CollapsableFilterGroup">
      <h5>
        {title}
        <IconButton
          icon={hidden ? "chevron-up" : "chevron-down"}
          onClick={(evt) => {
            evt.stopPropagation();
            setHidden(!hidden);
          }}
        />
      </h5>
      {!hidden ?
        <ul onClick={(evt) => evt.stopPropagation()}>
          {valueSet.map((value) => (
            <li key={value}>
              <Checkbox
                checked={selectedCategoryValues.includes(value)}
                label={value}
                onChange={() => setCategoryValue(value)}
              />
              <Button flavor="link" onClick={() => setCategoryValue(value, true)}>
                only
              </Button>
            </li>
          ))}
        </ul>
      : null}
    </li>
  );
}

import { IconsType } from "@/components/SvgIcon";

import { Unit } from "@/js/types/utils";

import { Unit57dEnum } from "@/Api/generated";

export type MeterTypes = "ELECTRIC" | "FUELS" | "WATER" | "WASTE" | "DISTRICT_ENERGY";

export const MeterGroups: { key: MeterTypes; displayName: string; icon: IconsType }[] = [
  {
    key: "ELECTRIC",
    displayName: "Electric",
    icon: "lightning",
  },
  {
    key: "FUELS",
    displayName: "Fuels",
    icon: "wildfire",
  },
  {
    key: "WATER",
    displayName: "Water",
    icon: "drop",
  },
  {
    key: "WASTE",
    displayName: "Waste",
    icon: "trash",
  },
  {
    key: "DISTRICT_ENERGY",
    displayName: "District Energy",
    icon: "manufacturing",
  },
];

export const SupportedUnits: { unit: Unit; meterTypes: MeterTypes[] }[] = [
  { unit: Unit57dEnum.KWH, meterTypes: ["ELECTRIC", "FUELS"] },
  { unit: Unit57dEnum.KBTU, meterTypes: ["ELECTRIC", "FUELS", "DISTRICT_ENERGY"] },
  { unit: Unit57dEnum.THERMS, meterTypes: ["FUELS"] },
  { unit: "cubic_meters", meterTypes: ["FUELS", "WATER"] },
  { unit: Unit57dEnum.GALLONS, meterTypes: ["WATER"] },
  { unit: Unit57dEnum.METRIC_TONS, meterTypes: ["WASTE"] },
];

export const MeterSubTypes: {
  display: string;
  value: string;
  meterType: MeterTypes;
}[] = [
  { display: "Grid", value: "energy.electric.grid", meterType: "ELECTRIC" },
  {
    display: "Onsite Solar",
    value: "energy.electric.solar",
    meterType: "ELECTRIC",
  },
  { display: "Onsite Wind", value: "energy.electric.wind", meterType: "ELECTRIC" },
  { display: "Natural Gas", value: "energy.fuels.natural_gas", meterType: "FUELS" },
  { display: "Propane", value: "energy.fuels.propane", meterType: "FUELS" },
  { display: "Fuel Oil", value: "energy.fuels.fuel_oil", meterType: "FUELS" },
  { display: "Diesel", value: "energy.fuels.diesel", meterType: "FUELS" },
  { display: "Coal", value: "energy.fuels.coal", meterType: "FUELS" },
  { display: "Coke", value: "energy.fuels.coke", meterType: "FUELS" },
  { display: "Kerosene", value: "energy.fuels.kerosene", meterType: "FUELS" },
  { display: "Other", value: "energy.fuels.other", meterType: "FUELS" },
  {
    display: "District Steam",
    value: "energy.district_energy.steam",
    meterType: "DISTRICT_ENERGY",
  },
  {
    display: "District Hot Water",
    value: "energy.district_energy.hot_water",
    meterType: "DISTRICT_ENERGY",
  },
  {
    display: "District Chilled Water",
    value: "energy.district_energy.chilled_water",
    meterType: "DISTRICT_ENERGY",
  },
  {
    display: "Municipal Potable Water Indoor",
    value: "water.municipal_potable_water.indoor",
    meterType: "WATER",
  },
  {
    display: "Municipal Reclaimed Water Indoor",
    value: "water.municipal_reclaimed_water.indoor",
    meterType: "WATER",
  },
  { display: "Well Water Indoor", value: "water.well_water.indoor", meterType: "WATER" },
  { display: "Other", value: "water.other", meterType: "WATER" },
  { display: "Disposed", value: "waste.disposed", meterType: "WASTE" },
  { display: "Donated / Reused", value: "waste.donated_reused", meterType: "WASTE" },
  { display: "Recycled", value: "waste.recycled", meterType: "WASTE" },
  { display: "Composted", value: "waste.composted", meterType: "WASTE" },
];

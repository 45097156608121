import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";

import useResizeObserver from "./useResizeObserver";

/**
 * By default, because canvas elements do not take width/height values in anything other than
 * pixels, we measure its default size as it is sized in CSS relative to a parent, and set those.
 * We have to resize, and thus redraw, when the window resizes.
 */
export default function useCanvas() {
  const [forceUpdate, setForceUpdate] = useState({});
  const [dpr, setDpr] = useState(window.devicePixelRatio);

  const canvasRef = useRef<HTMLCanvasElement>();

  useResizeObserver(
    canvasRef.current?.parentElement,
    useCallback(() => setForceUpdate({}), []),
  );

  /**
   * Make sure we resize our canvas element with our dpp changes.
   */
  useEffect(() => {
    const onDprChange = () => setDpr(window.devicePixelRatio);
    const media = window.matchMedia(`(resolution: ${dpr}dppx)`);

    media.addEventListener("change", onDprChange);

    return () => {
      media.removeEventListener("change", onDprChange);
    };
  }, [dpr]);

  /**
   * Dynamically sets the width/height attributes, both in html (which is multipled by dpr) and CSS
   * (which is not). The resizeObserver watches its parent for changes and then will re-run this
   * effect.
   */
  useLayoutEffect(() => {
    canvasRef.current.removeAttribute("width");
    canvasRef.current.removeAttribute("height");
    canvasRef.current.removeAttribute("style");

    // Get the DPR and size of the canvas
    const CSSWidth = canvasRef.current.offsetWidth;
    const CSSHeight = canvasRef.current.offsetHeight;

    // Set the "actual" size of the canvas
    canvasRef.current.width = Math.floor(CSSWidth * dpr);
    canvasRef.current.height = Math.floor(CSSHeight * dpr);

    // Set the "drawn" size of the canvas
    canvasRef.current.style.width = `${CSSWidth}px`;
    canvasRef.current.style.height = `${CSSHeight}px`;
  }, [dpr, forceUpdate]);

  return { dpr, canvasRef };
}

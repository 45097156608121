import type { ButtonElement, ButtonProps } from "@/components/Button";
import type { HovercardToggleHandle } from "@/components/HovercardToggle";
import type { IconsType } from "@/components/SvgIcon";
import type { ForwardedRef, MutableRefObject, ReactElement } from "react";

import isEqual from "lodash/isEqual";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

import Button from "@/components/Button";
import IconButton from "@/components/Button/IconButton";
import EmptyState from "@/components/EmptyState";
import HovercardToggle from "@/components/HovercardToggle";
import SearchSelect from "@/components/Select/SearchSelect";
import SelectableList, {
  SelectableListGroup,
  SelectableListItem,
} from "@/components/SelectableList";
import SvgIcon from "@/components/SvgIcon";

import { classnames } from "@/js/utils/cambio";

export const EMPTY_DISPLAY = "--";

interface MultiSelectProps<T> {
  /** Whether the trigger button should take up the full width of its parent */
  block?: boolean;
  disabled?: boolean;
  /**
   * This is called when the hovercard closes without any changes to the selections.
   */
  onClose?: (values: T[]) => void;
  /**
   * Callback invoked only after the user confirms all selections, which they can do by clicking out
   * of the hovercard or by clicking the "Confirm" button".
   */
  onSelect: (values: T[]) => void;
  options: Option<T>[];
  /** Passing this will take precedence over `options` */
  optionGroups?: OptionGroup<T>[];
  children?:
    | ReactElement
    | (({
        text,
        invalid,
        disabled,
        ref,
      }: {
        text: string;
        disabled: boolean;
        invalid: boolean;
        ref: MutableRefObject<HTMLElement>;
      }) => ReactElement);
  icon?: IconsType;
  id?: string;
  /** True when failing form validation */
  invalid?: boolean;
  /** When true, the hovercard will be open initially on render */
  initiallyOpen?: boolean;
  label?: string;
  placeholder?: string;
  /** This is specific text shown in the large set picker when no items are selected */
  promptText?: string;
  size?: ButtonProps["size"];
  useRawDisplay?: boolean;
  /**
   * Like all other inputs, this takes a value property passed in representing the current input
   * value. Unlike the others, though, it can take an array instead of a string | number primitive.
   */
  value?: T[];
}

export interface Option<T> extends Omit<SelectableListItem, "key"> {
  value: T;
}

export interface OptionGroup<T> extends Omit<SelectableListGroup, "items"> {
  items: Option<T>[];
}

export interface MultiSelectHandle {
  focus: () => void;
  open: () => void;
}

const MAX_SMALL_SET_ITEMS = 40;

/**
 * Multiselect component that holds all selections as internal state until the user "confirms" them,
 * either by clicking the "confirm" button or by clicking out of the hovercard. We have a default
 * child component, but a custom child (trigger element) can be passed in. When it's a function,
 * it is passed the display text, which uses the following logic:
 *
 * * if only one item is selected, we show that item's display
 * * if two items are selected, we show both separated by "and"
 * * if there are more than two items, we show the first two appended with `and ${n - 2} more`
 *
 * Additionally, for large option sets, we'll show a search interface rather than listing everything
 * out in a single list. This is still TODO.
 */
const MultiSelect = <T extends string | number = string>(
  {
    block,
    options = [],
    optionGroups = [],
    onClose,
    onSelect,
    children,
    disabled,
    icon,
    id,
    invalid,
    initiallyOpen,
    label,
    placeholder,
    promptText,
    size,
    value,
    useRawDisplay = false,
  }: MultiSelectProps<T>,
  ref: ForwardedRef<MultiSelectHandle>,
) => {
  /**
   * This is our internal values state, because we allow the user to make all selections in batch
   * before we react to all (as opposed to invoking our onSelect callback on each selection/
   * de-selection).
   */
  const [values, setValues] = useState(value || []);

  const triggerRef = useRef<ButtonElement>(null);
  const hovercardRef = useRef<HovercardToggleHandle>(null);

  const getDisplayText = () => {
    const orderedDisplays = values
      .map((value) => getItemDisplay(value, options, optionGroups))
      .sort();

    switch (values.length) {
      case 0:
        return placeholder;
      case 1:
      case 2:
        return orderedDisplays.join("\xa0and\xa0");
      default:
        return `${orderedDisplays.slice(0, 2).join(",\xa0")},\xa0and\xa0${
          values.length - 2
        }\xa0more`;
    }
  };

  const getRawDisplayItems = () => {
    const displayItems = values.map((value) => getItemDisplay(value, options, optionGroups));

    return displayItems.length === 0 ? placeholder : displayItems;
  };

  /**
   * Because we have to keep state in this component (since we defer reacting to each selection
   * until the user is done choosing), this block just ensures that if a parent component ever
   * differs in state, that it takes precedence and this reusable component will conform to it.
   */
  useEffect(() => {
    if (value?.join() && value?.join() !== values.join()) {
      setValues(value);
    }
  }, [value?.join()]);

  /**
   * Allows a parent to toggle the list open and close and also to focus the button trigger.
   */
  useImperativeHandle(ref, () => ({
    focus: () => triggerRef.current.focus(),
    open: () => hovercardRef.current.open(),
  }));

  return (
    <span className={classnames("MultiSelect", { invalid, block })} role="listbox" id={id}>
      <HovercardToggle
        ref={hovercardRef}
        contents={() => {
          const ContentComponent =
            options.length >= MAX_SMALL_SET_ITEMS ? MultiSelectLargeSetPicker : MultiSelectContent;

          return (
            <ContentComponent<T>
              options={options}
              optionGroups={optionGroups}
              values={values}
              onSelectItem={(itemKey) =>
                setValues(
                  values.includes(itemKey) ?
                    values.filter((val) => val !== itemKey)
                  : values.concat(itemKey),
                )
              }
              placeholder={placeholder}
              promptText={promptText}
            />
          );
        }}
        disabled={disabled}
        initiallyOpen={initiallyOpen}
        // only call onSelect on close if something has changed
        onClose={() => (!isEqual(value, values) ? onSelect(values) : onClose?.(values))}
      >
        {typeof children === "function" ?
          children({ text: getDisplayText(), invalid, disabled, ref: triggerRef })
        : children || (
            <Button className="MultiSelectTriggerButton" ref={triggerRef} icon={icon} label={label}>
              {useRawDisplay ? getRawDisplayItems() : getDisplayText()}
              <SvgIcon name="chevron-down" />
            </Button>
          )
        }
      </HovercardToggle>
    </span>
  );
};

interface MultiSelectContentProps<T> {
  options: Option<T>[];
  optionGroups: OptionGroup<T>[];
  values: T[];
  onSelectItem: (value: T) => void;
  placeholder?: string;
  promptText?: string;
}

export function MultiSelectContent<T extends string | number = string>({
  options,
  optionGroups,
  values = [],
  onSelectItem,
}: MultiSelectContentProps<T>) {
  const optionMap = (option: Option<T>) => ({
    ...option,
    key: option.value,
    ...(values.includes(option.value) ? { selected: true } : {}),
  });

  return (
    <div className="MultiSelectContent">
      {options.length || optionGroups.length ?
        <span onClick={(evt) => evt.stopPropagation()}>
          <SelectableList
            // only one of optionGroups or options should be passed. SelectableList will prioritize groups
            groups={optionGroups.map((optGroup) => ({
              ...optGroup,
              items: optGroup.items.map(optionMap),
            }))}
            items={options.map(optionMap)}
            onClick={(item) => onSelectItem(item.key as T)}
          />
        </span>
      : <EmptyState message="No items to display" />}
      <footer>
        <Button flavor="primary">Confirm</Button>
      </footer>
    </div>
  );
}

/**
 * Like MultiSelectContent but for larger datasets. We use a SearchSelect to pick out options
 * instead of display them all within view.
 */
export function MultiSelectLargeSetPicker<T extends string | number = string>({
  values = [],
  options,
  optionGroups,
  placeholder,
  promptText,
  onSelectItem,
}: MultiSelectContentProps<T>) {
  const filterExistingItems = ({ value }: { value: T }) => !values.includes(value);

  return (
    <div className="MultiSelectLargeSetPicker">
      <span onClick={(evt) => evt.stopPropagation()}>
        <SearchSelect
          key={values.length}
          options={options.filter(filterExistingItems)}
          optionGroups={optionGroups
            .map((group) => ({ ...group, items: group.items.filter(filterExistingItems) }))
            .filter(({ items }) => items.length)}
          onSelect={(val) => onSelectItem(val as T)}
          placeholder={placeholder}
        />
      </span>
      {values.length ?
        <ul className="selected-values">
          {values.map((value) => (
            <li key={value}>
              <span>{getItemDisplay(value, options, optionGroups)}</span>
              <IconButton
                icon="x"
                onClick={(evt) => {
                  // this stops the parent hovercards from closing
                  evt.nativeEvent.stopImmediatePropagation();
                  onSelectItem(value);
                }}
                size="small"
              />
            </li>
          ))}
        </ul>
      : promptText ?
        <p>{promptText}</p>
      : null}
      <footer>
        <Button flavor="primary">Confirm</Button>
      </footer>
    </div>
  );
}

/**
 * Returns the display string for a given option value. Considers both option groups and options.
 */
export function getItemDisplay<T extends string | number = string>(
  value: T,
  options: Option<T>[],
  optionGroups: OptionGroup<T>[],
) {
  return optionGroups.length ?
      optionGroups.flatMap(({ items }) => items).find((option) => option.value === value)
        ?.display ?? EMPTY_DISPLAY
    : options.find((option) => option.value === value)?.display ?? EMPTY_DISPLAY;
}

export default forwardRef(MultiSelect);

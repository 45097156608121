import type { Meter } from "@/js/models/MetersCollection";

import orderBy from "lodash/orderBy";

import { isStringNumeric } from "@/js/utils/isStringNumeric";
import { capitalizeWords } from "@/js/utils/stringFormatter";

import {
  IngestionRecordSourceEnum,
  MeterTypeReference,
  Source2c2Enum as SourceEnum,
} from "@/Api/generated";

import { MeterTypes } from "../constants";

/**
 * Sorting function used by the Meter tables. If this turns out to be more widely used, we can
 * extract this into the utils/camio.ts general file. Looking forward to building out our own
 * Table component, this is the logic we might want built in.
 */
export const sortTableData = <T>(
  data: T[],
  descending: boolean,
  { compare, key }: { compare?: (item: T) => any; key?: keyof T },
) =>
  orderBy(
    data,
    [
      (item) => {
        let value = typeof compare === "function" ? compare(item) : item[key];

        return (
          typeof value === "number" ? value
          : isStringNumeric(value) ? parseFloat(value)
          : String(value).toLowerCase()
        );
      },
    ],
    [descending ? "desc" : "asc"],
  );

/**
 * The way the API represents meter types is inconsistent with how we display them. We generally
 * show water/waste alongside electricity/district energy/fuels, but the former group is top-level,
 * and the latter group is at subtype1. This also means that when we want to look up subtypes, we
 * have to look at different levels depending on the reference type.
 */
export function getMeterType(meter: { meter_type_reference: MeterTypeReference }): MeterTypes {
  const meterTypeReference = meter.meter_type_reference;
  const referenceType = meterTypeReference?.type;

  return (
    ["WATER", "WASTE"].includes(referenceType) ? referenceType
    : referenceType === "ENERGY" ? meterTypeReference?.subtype_1
    : null) as MeterTypes;
}

export function getMeterSubtype(meter: Meter, fallback = "") {
  const meterTypeReference = meter.meter_type_reference;
  const referenceType = meterTypeReference?.type;

  const subType =
    ["WATER", "WASTE"].includes(referenceType) ? meterTypeReference?.subtype_1
    : referenceType === "ENERGY" ? meterTypeReference?.subtype_2
    : null;

  return !subType ? fallback : getMeterTypeDisplay(subType);
}

/**
 * Gets the display name for a meter type, either primary or subtype.
 */
export function getMeterTypeDisplay(meterType: string) {
  return capitalizeWords(meterType.replaceAll("_", " ").toLowerCase());
}

/**
 * We often will display a meter name in this format, for example in the add data modals.
 */
export function getMeterName(meter: Meter) {
  return (
    `${getMeterTypeDisplay(getMeterType(meter))} Meter ${meter.utility_meter_id}` +
    (meter.utility_provider?.name ? `\xa0(${meter.utility_provider.name})` : "")
  );
}

/**
 * Note: The API should merge SourceEa1Enum with IngestionRecordSourceEnum. This is tech debt, but
 * until that is addresssed, the webapp is supporting both here.
 */
export const getCustomerFriendlyDataSource = (
  dataSource: IngestionRecordSourceEnum | SourceEnum,
) => {
  switch (dataSource) {
    case IngestionRecordSourceEnum.UTILITY_PORTAL_CSV:
      return "Utility Portal - CSV Upload";
    case IngestionRecordSourceEnum.UTILITY_PORTAL_MANUAL_INPUT:
      return "Utility Portal - Entry";
    case SourceEnum.URJANET:
    case IngestionRecordSourceEnum.URJANET_API:
      return "Cambio Sync";
    case SourceEnum.ENERGY_STAR:
    case IngestionRecordSourceEnum.ENERGY_STAR:
      return "EnergyStar";
    case IngestionRecordSourceEnum.ADMIN_BILL_UPLOAD:
      return "Utility Portal - Bill Upload";
    case IngestionRecordSourceEnum.GRESB_ASSET_SPREADSHEET:
    case SourceEnum.MANUAL_ENTRY:
      return "Direct Upload";
    default:
      return "--";
  }
};
